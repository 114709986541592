import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { getGeneralApi, postGeneralApi } from "../api/general";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { getFechaCorta } from "../utiles/fechas";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method

export function PptoActualizador(props) {
    const { pedido, callBack } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [datosPptos, setDatosPptos] = useState({});
    const [pptoDrop, setPptoDrop] = useState(pedido.ppto.id);
    const labelGeneral = "Cambiar Presupuesto";

    addLocale("es", CALENDAR_LOCALE_ES);

    useEffect(() => {
        async function getListaDatos(idUsuario) {
            await getGeneralApi("listaPptosCliente", idUsuario)
                .then((respuesta) => {
                    if (respuesta?.data?.success) {
                        const arrayTmp = [];
                        respuesta.data.items.forEach((elemento) => {
                            let obj = {};
                            obj.id = elemento.id;
                            obj.desc = "Nº " + elemento.id + ", Ref: " + elemento.ref + ", Fecha: " + getFechaCorta(elemento.fecha);
                            if (elemento.id !== pedido.ppto.id) {
                                arrayTmp.push(obj);
                            }
                        });
                        setDatosPptos(arrayTmp);
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de presupuestos" });
                    }
                })
                .catch((response) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
                });
        }
        getListaDatos(pedido.ppto.usuario.id);
    }, [pedido.ppto]);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            ppto: { id: pptoDrop, ref: "" }, //Hack para primeReact Dropdown
        };

        let haCambiadoPpto = pedido.ppto.id !== pptoDrop;
        if (!haCambiadoPpto) {
            toast.current.show({ severity: "warn", summary: "Aviso", detail: "Se debe seleccionar un presupuesto" });
            return;
        }

        console.log("ppto.id: " + pedido.ppto.id + ", pptoDrop: " + pptoDrop);

        confirmDialog({
            message: "¿Está seguro de cambiar el presupuesto asociado a este pedido?",
            header: "Confirmacón requerida",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Cambiar",
            rejectLabel: "No",
            accept: () => aceptaConfirm(),
            reject: () => rechazaConfirm(),
        });

        function aceptaConfirm() {
            postGeneralApi("updateDatosPedidoPpto", datosFormularioEnvio, pedido.id)
                .then((respuesta) => {
                    if (respuesta?.data?.success) {
                        toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                        overlayPanel.current.hide();
                        callBack(Date.now());
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                    }
                })
                .catch((response) => {
                    console.log("Error API[" + response + "]");
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
                });
        }

        function rechazaConfirm() {
            return;
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="ppto">Cambiar por el siguiente presupuesto:</label>
                                <Dropdown name="ppto" optionValue="id" optionLabel="desc" value={pptoDrop} options={Array.from(datosPptos)} onChange={(e) => setPptoDrop(e.value)} placeholder="Seleccionar Presupuesto" />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="boton" style={{ visibility: "hidden" }}>
                                    Cambiar
                                </label>
                                <Button id="boton" label="CAMBIAR" type="submit"></Button>
                                <ConfirmDialog />
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
