import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getGeneralApi, postGeneralApi } from "../api/general";
import { Dropdown } from "primereact/dropdown";
import { estadosExpedicion } from "../api/varios";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";

export function ExpedicionDialog(props) {
    const { callBack, expedicion, labelEditar, modoCrear = false } = props;
    const [display, setDisplay] = useState(false);
    const [agencias, setAgencias] = useState({});
    const [fechaCal, setFechaCal] = useState(expedicion?.fecha);
    const [estadoExpedicionDrop, setEstadoExpedicionDrop] = useState(expedicion?.estadoExpedicion);
    const [agenciaDrop, setAgenciaDrop] = useState(expedicion?.agencia?.id);
    const [calcularSemana, setCalcularSemana] = useState(modoCrear);
    const toast = useRef(null);
    const formulario = useRef(null);

    addLocale("es", CALENDAR_LOCALE_ES);

    useEffect(() => {
        getGeneralApi("listaAgencias")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setAgencias(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de agencias" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const initDatos = () => {
        setFechaCal(expedicion?.fecha);
        setEstadoExpedicionDrop(expedicion?.estadoExpedicion);
        setAgenciaDrop(expedicion?.agencia?.id);
    };

    const onShow = () => {};

    const onHide = () => {
        setDisplay(false);
        initDatos();
    };

    const onSubmit = (e) => {
        const datosFormularioEnvio = {
            fecha: fechaCal,
            estadoExpedicion: estadoExpedicionDrop, //Hack para primeReact Dropdown
            agencia: { id: agenciaDrop }, //Hack para primeReact Dropdown
            semana: calcularSemana ? null : formulario.current.semana.value,
            ref: formulario.current.ref.value,
            descripcion: formulario.current.descripcion.value,
        };
        //console.log("/>>"+JSON.stringify(datosFormularioEnvio));
        postGeneralApi(modoCrear ? "createExpedicion" : "updateDatosExpedicion", datosFormularioEnvio, expedicion.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    setDisplay(false);
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    const renderFooter = () => {
        return (
            <div>
                <Button type="button" label="Salir" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                <Button type="button" label={modoCrear ? "Crear" : "Actualizar"} icon="pi pi-check" onClick={() => onSubmit()} />
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <Button type="button" label={modoCrear ? "Nuevo" : labelEditar ? labelEditar : ""} className="p-button-secondary" icon="pi pi-external-link" onClick={() => setDisplay(true)} />
            <Dialog header={modoCrear ? "Insertar expedición" : "Modificar expedición nº " + expedicion.id} visible={display} style={{ width: "50vw" }} footer={renderFooter()} onHide={() => onHide()} onShow={() => onShow()}>
                <form ref={formulario} id="formulario">
                    <div className="card p-fluid">
                        <h5>{modoCrear ? "Crear Expedición" : "Actualizar Expedición"}</h5>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-3">
                                <label htmlFor="fecha">Fecha</label>
                                <Calendar showButtonBar={true} locale="es" dateFormat="dd/mm/yy" id="fecha" showIcon value={new Date(fechaCal)} onChange={(e) => setFechaCal(e.value)}></Calendar>
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="estado">Estado</label>
                                <br />
                                <Dropdown name="estadoExpedicion" value={estadoExpedicionDrop} options={estadosExpedicion} onChange={(e) => setEstadoExpedicionDrop(e.value)} placeholder="Seleccionar Estado" />
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="semanaBoton">{calcularSemana ? "Calcular semana" : "Semana manual"}</label>
                                <br />
                                <InputSwitch checked={calcularSemana} onChange={(e) => setCalcularSemana(!calcularSemana)} />
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="semana">Semana</label>
                                <br />
                                <InputText disabled={calcularSemana} id="semana" name="semana" placeholder="Semana (SS-DD)" defaultValue={expedicion.semana} />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="agencia">Agencia de Transporte</label>
                                <Dropdown name="agencia" optionValue="id" optionLabel="nombre" value={agenciaDrop} options={agencias} onChange={(e) => setAgenciaDrop(e.value)} placeholder="Seleccionar Agencia" />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="ref">Referencia</label>
                                <br />
                                <InputText id="ref" name="ref" placeholder="Referencia de la expedición" defaultValue={expedicion.ref} />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="descripcion">Más información</label>
                                <br />
                                <InputTextarea id="descripcion" name="detalles" placeholder="Descripción de la incidencia" cols="40" rows="4" defaultValue={expedicion.descripcion} />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
}
