import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { postGeneralApi, getGeneralApi } from "../api/general";
import Moment from "react-moment";
import { Button } from "primereact/button";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { BotonIdTexto } from "../componentes/BotonIdTexto";
import { Dropdown } from "primereact/dropdown";
import { estadosItemMasTodos, tipoFechaItems } from "../api/varios";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import { useHistory } from "react-router";

export const Items = () => {
    const [items, setItems] = useState(null);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [estadosItemDrop, setEstadosItemDrop] = useState("TODOS");
    const [fecha1, setfecha1] = useState(moment().subtract(6, "months").toDate());
    const [fecha2, setfecha2] = useState(moment().add(6, "months").toDate());
    const [tipoFechaDrop, setTipoFechaDrop] = useState("fechaEstimada");
    const [reload, setReload] = useState(Date.now());
    const [expedicionDrop, setExpedicionDrop] = useState();
    const [datosExpediciones, setDatosExpediciones] = useState({});
    const [bultoDrop, setBultoDrop] = useState();
    const [datosBultos, setDatosBultos] = useState({});
    const [usuarioDrop, setUsuarioDrop] = useState(0);
    const [datosUsuarios, setDatosUsuarios] = useState({});
    const history = useHistory();

    addLocale("es", CALENDAR_LOCALE_ES);

    const toast = useRef(null);

    useEffect(() => {
        let fecha2HastaFinDia = moment(fecha2);
        fecha2HastaFinDia.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        const datosFormularioEnvio = {
            estadoItem: estadosItemDrop, //Hack para primeReact Dropdown
            usuario: usuarioDrop, //Hack para primeReact Dropdown
            fecha1: fecha1,
            fecha2: fecha2HastaFinDia,
            tipoFecha: tipoFechaDrop,
        };
        setLoading(true);
        postGeneralApi("listaItems", datosFormularioEnvio)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setItems(respuesta.data.items);
                    setLoading(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
        getListaUsuarios();
    }, [estadosItemDrop, usuarioDrop, fecha1, fecha2, tipoFechaDrop, reload]);

    async function cargaDatosExpediciones() {
        await getGeneralApi("listaExpedicionesProximas")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosExpediciones(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.respuesta });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        cargaDatosExpediciones();
    }, [reload]);

    useEffect(() => {
        if (typeof expedicionDrop !== "undefined") {
            let bultosDeLaExpedicionSeleccionada = datosExpediciones.find((x) => x.id === expedicionDrop).bultos;
            bultosDeLaExpedicionSeleccionada = [{ id: "0", ref: "Nuevo Bulto/Albarán" }, ...bultosDeLaExpedicionSeleccionada];
            setDatosBultos(bultosDeLaExpedicionSeleccionada);
        }
    }, [datosExpediciones, expedicionDrop]);

    const onSubmitFiltro = (e) => {
        e.preventDefault();
        setReload(Date.now());
    };

    const onSubmitAsigna = (e) => {
        e.preventDefault();
        if (selectedItems == null || selectedItems.length === 0) {
            toast.current.show({ severity: "warn", summary: "Aviso", detail: "Se debe marcar al menos una expedición" });
        } else if (expedicionDrop <= 0 || bultoDrop < 0) {
            toast.current.show({ severity: "warn", summary: "Aviso", detail: "Se debe seleccionar la expedición y el bulto" });
        } else if (bultoDrop === "0") {
            confirmDialog({
                message: "¿Esto creará un nuevo Bulto para asignar las puertas seleccionadas, está seguro?",
                header: "Confirmacón requerida",
                icon: "pi pi-exclamation-triangle",
                acceptLabel: "Crear Bulto y Asignar",
                rejectLabel: "No",
                accept: () => aceptaConfirm(),
                reject: () => rechazaConfirm(),
            });
        } else {
            aceptaConfirm();
        }

        function aceptaConfirm() {
            const datosFormularioEnvioAsignador = { expedicion: expedicionDrop, bulto: bultoDrop, selectedItems };
            //console.log(JSON.stringify(datosFormularioEnvioAsignador));
            postGeneralApi("asignaPuertasBulto", datosFormularioEnvioAsignador)
                .then((respuesta) => {
                    if (respuesta?.data?.success) {
                        toast.current.show({ severity: "success", summary: "OK", detail: respuesta?.data?.mensaje });
                        setReload(Date.now());
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                    }
                })
                .catch((response) => {
                    toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
                });
        }

        function rechazaConfirm() {
            return;
        }
    };

    async function getListaUsuarios() {
        await getGeneralApi("listaUsuariosActivosMasTodos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosUsuarios(respuesta.data.items);
                    //console.log("/>> " + JSON.stringify(respuesta.data.items));
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de usuarios" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    const itemsTableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar en puertas/items" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const fechaTemplate = (data, props) => {
        return <>{data[props.field] ? <Moment format="DD/MM/YYYY">{data[props.field]}</Moment> : "-"}</>;
    };

    const statusBodyTemplate = (data) => {
        return <EstadoTexto estado={data?.estadoItem} />;
    };

    const bultoTemplate = (data) => {
        return (
            <>
                <strong>{data?.bulto?.expedicion?.semana}</strong> {data?.bulto?.ref}
            </>
        );
    };

    const botonIdItemTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="building" ruta="/item/">
                    {String(data.id).padStart(5, "0")}
                </BotonIdTexto>
            </>
        );
    };

    const botonIdPedidoTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="id-card" ruta="/pedido/">
                    {String(data.pedido.id).padStart(5, "0")}
                </BotonIdTexto>
            </>
        );
    };

    const expedicionDropTemplate = (options) => {
        return (
            <>
                <strong>{options?.semana}</strong> · <Moment format="DD/MM/YYYY">{options?.fecha}</Moment>
            </>
        );
    };

    const bultoDropTemplate = (options) => {
        return (
            <>
                <strong>{options?.ref}</strong> {options.descripcion}
            </>
        );
    };

    const usuarioTemplate = (data) => (
        <Button
            icon="pi pi-user"
            label={data.pedido.ppto.usuario.nombre}
            iconPos="left"
            type="button"
            className="p-button-secondary"
            onClick={(e) => {
                history.push("/cliente/" + data.pedido.ppto.usuario.id);
            }}
        ></Button>
    );

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>
                            <i className="p-pr-2 pi pi-building" style={{ fontSize: "1.5rem", float: "left" }}></i>Puertas / Items
                        </h5>
                        <form onSubmit={onSubmitFiltro}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="estado">Estado </label>
                                    {estadosItemDrop && <Dropdown name="estado" value={estadosItemDrop} options={Array.from(estadosItemMasTodos)} onChange={(e) => setEstadosItemDrop(e.value)} placeholder="Seleccionar Estado" />}
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="usuario">Cliente </label>
                                    <Dropdown filter showClear id="usuario" name="usuario" optionValue="id" optionLabel="nombre" value={usuarioDrop} options={Array.from(datosUsuarios)} onChange={(e) => setUsuarioDrop(e.value)} placeholder="Seleccionar Cliente" />
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="desde">Desde</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="desde" showIcon value={fecha1} onChange={(e) => setfecha1(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="hasta">Hasta</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="hasta" showIcon value={fecha2} onChange={(e) => setfecha2(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="estado">Filtrar por</label>
                                    {tipoFechaDrop && <Dropdown name="estado" value={tipoFechaDrop} options={Array.from(tipoFechaItems)} onChange={(e) => setTipoFechaDrop(e.value)} placeholder="Filtrar por fechas" />}
                                </div>
                                <div className="p-field p-col-1">
                                    <label htmlFor="buscar" style={{ visibility: "hidden" }}>
                                        Buscar
                                    </label>
                                    <Button icon="pi pi-replay" label="Recargar" type="submit"></Button>
                                </div>
                            </div>
                        </form>
                        <DataTable
                            value={items}
                            paginator
                            selectionPageOnly
                            selectionMode="checkbox"
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            rows={20}
                            dataKey="id"
                            rowHover
                            selection={selectedItems}
                            onSelectionChange={(e) => setSelectedItems(e.value)}
                            globalFilter={globalFilter}
                            emptyMessage="No se han encontrado puertas/items"
                            loading={loading}
                            header={itemsTableHeader}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                            <Column headerStyle={{ width: "8rem", textAlign: "center" }} field="id" header="Número" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdItemTemplate}></Column>
                            <Column headerStyle={{ width: "12em" }} field="serial" header="Nº Serie" sortable></Column>
                            <Column headerStyle={{ width: "6rem" }} field="estadoItem" header="Estado" sortable body={statusBodyTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="fechaEstimada" header="Fecha Estimada" sortable body={fechaTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="fechaSalida" header="Fecha Salida" sortable body={fechaTemplate}></Column>
                            <Column field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                            <Column field="comentario" header="Comentarios" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "12rem" }} field={bultoTemplate} header="Bulto" sortable></Column>
                            <Column headerStyle={{ width: "8rem" }} field="pedido" header="Pedido" sortable body={botonIdPedidoTemplate}></Column>
                            <Column headerStyle={{ width: "14rem" }} field="usuario" header="Cliente" sortable body={usuarioTemplate}></Column>
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de puertas/items obtenidos: <strong>{items?.length}</strong>
                        </div>

                        <form onSubmit={onSubmitAsigna}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="expedicion">Expedición</label>
                                    <Dropdown name="expedicion" optionValue="id" optionLabel="semana" itemTemplate={expedicionDropTemplate} value={expedicionDrop} options={datosExpediciones} onChange={(e) => setExpedicionDrop(e.value)} placeholder="Seleccionar Expedición" />
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="bulto">Bulto/Albarán</label>
                                    <Dropdown name="bulto" optionValue="id" optionLabel="ref" itemTemplate={bultoDropTemplate} value={bultoDrop} options={datosBultos} onChange={(e) => setBultoDrop(e.value)} placeholder="Seleccionar Bulto/Albarán" />
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="buscar" style={{ visibility: "hidden" }}>
                                        Buscar
                                    </label>
                                    <Button icon="pi pi-replay" label="Asignar" type="submit"></Button>
                                    <ConfirmDialog />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
