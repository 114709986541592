import React, { useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { postGeneralApi } from "../api/general";

export function IncidenciaNueva(props) {
    const { callBack, id, modoItem = false } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const labelGeneral = "Crear Incidencia";

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            origen: e.target.origen.value,
            motivo: e.target.motivo.value,
            detalles: e.target.detalles.value,
        };
        if (!datosFormularioEnvio.origen || !datosFormularioEnvio.motivo || !datosFormularioEnvio.detalles) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "Deben rellenarse los tres campos" });
            return;
        }

        postGeneralApi(modoItem ? "createIncidenciaItem" : "updateDatosPedidoCreaIncidencia", datosFormularioEnvio, id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined p-button-warning" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>

                        <div className="p-field">
                            <label htmlFor="origen">Origen</label>
                            <br />
                            <InputText id="origen" name="origen" placeholder="Origen de la incidencia" />
                        </div>
                        <div className="p-field">
                            <label htmlFor="motivo">Motivo</label>
                            <br />
                            <InputText id="motivo" name="motivo" placeholder="Motivo de la incidencia" />
                        </div>
                        <div className="p-field">
                            <label htmlFor="detalles">Detalles</label>
                            <br />
                            <InputTextarea id="detalles" name="detalles" placeholder="Detalles de la incidencia" cols="40" rows="4" />
                        </div>
                        <div className="p-field">
                            <Button className="p-button-warning" label="CREAR" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
