import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import Moment from "react-moment";
import { Button } from "primereact/button";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { Dropdown } from "primereact/dropdown";
import { estadosExpedicionMasTodos } from "../api/varios";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { ExpedicionDialog } from "../componentes/ExpedicionDialog";
import { BotonIdTexto } from "../componentes/BotonIdTexto";

export const Expediciones = () => {
    const [expediciones, setExpediciones] = useState(null);
    const [selectedExpedidiones, setSelectedExpediciones] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [estadoExpedicionDrop, setEstadoExpedidionDrop] = useState("TODOS");
    const [fecha1, setfecha1] = useState(moment().subtract(1, "months").toDate());
    const [fecha2, setfecha2] = useState(moment().add(3, "months").toDate());
    const [reload, setReload] = useState(Date.now());
    const [expandedRows, setExpandedRows] = useState([]);

    addLocale("es", CALENDAR_LOCALE_ES);

    const toast = useRef(null);
    let nuevaExpedicion = {};
    nuevaExpedicion.id = 0;
    nuevaExpedicion.estadoExpedicion = "CREADO";
    nuevaExpedicion.fecha = moment();

    useEffect(() => {
        let fecha2HastaFinDia = moment(fecha2);
        fecha2HastaFinDia.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        const datosFormularioEnvio = {
            estadoExpedicion: estadoExpedicionDrop, //Hack para primeReact Dropdown
            fecha1: fecha1,
            fecha2: fecha2HastaFinDia,
        };
        setLoading(true);
        postGeneralApi("listaExpediciones", datosFormularioEnvio)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setExpediciones(respuesta.data.items);
                    setLoading(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de expediciones" });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, [estadoExpedicionDrop, fecha1, fecha2, reload]);

    const onSubmit = (e) => {
        e.preventDefault();
        setReload(Date.now());
    };

    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar en expediciones" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const fechaTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <Moment format="DD/MM/YYYY">{data[props.field]}</Moment>
            </>
        );
    };

    const estadoExpedicionTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <EstadoTexto estado={data?.estadoExpedicion} />
            </>
        );
    };

    const botonDialogIdTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <ExpedicionDialog modoCrear={false} expedicion={data} callBack={setReload} />
            </>
        );
    };

    const botonIdExpedicionTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="pi pi-send" ruta="/expedicion/">
                    {String(data.id).padStart(4, "0")}
                </BotonIdTexto>
            </>
        );
    };

    const botonIdBultoTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="pi pi-box" ruta="/bulto/">
                    {String(data.id).padStart(5, "0")}
                </BotonIdTexto>
            </>
        );
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Bultos</h5>
                <DataTable value={data?.bultos} className="p-datatable-customerss" emptyMessage="No hay blultos en esta expedición">
                    <Column headerStyle={{ width: "10rem" }} field="id" header="Id" sortable body={botonIdBultoTemplate}></Column>
                    <Column headerStyle={{ width: "16rem" }} field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                    <Column headerStyle={{ width: "16rem" }} field="localizador" header="Localizador" sortable body={bodyTemplate}></Column>
                    <Column field="descripcion" header="Descripcion" sortable body={bodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (data) => {
        return data?.bultos?.length > 0;
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>
                            <i className="p-pr-2 pi pi-send" style={{ fontSize: "1.5rem", float: "left" }}></i>Expediciones
                        </h5>
                        <form onSubmit={onSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="estado">Estado</label>
                                    {estadoExpedicionDrop && <Dropdown name="estadoExpedicion" value={estadoExpedicionDrop} options={Array.from(estadosExpedicionMasTodos)} onChange={(e) => setEstadoExpedidionDrop(e.value)} placeholder="Seleccionar Estado" />}
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="desde">Desde</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="desde" showIcon value={fecha1} onChange={(e) => setfecha1(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="hasta">Hasta</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="hasta" showIcon value={fecha2} onChange={(e) => setfecha2(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="buscar" style={{ visibility: "hidden" }}>
                                        Buscar
                                    </label>
                                    <Button icon="pi pi-replay" label="Recargar" type="submit"></Button>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="Nuevo" style={{ visibility: "hidden" }}>
                                        Nuevo
                                    </label>
                                    <ExpedicionDialog modoCrear={true} expedicion={nuevaExpedicion} callBack={setReload} />
                                </div>
                            </div>
                        </form>
                        <DataTable
                            value={expediciones}
                            paginator
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            rows={20}
                            dataKey="id"
                            rowHover
                            selection={selectedExpedidiones}
                            onSelectionChange={(e) => setSelectedExpediciones(e.value)}
                            globalFilter={globalFilter}
                            emptyMessage="No se han encontrado expediciones"
                            loading={loading}
                            header={tableHeader}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate}
                        >
                            <Column expander={allowExpansion} headerStyle={{ width: "0em" }} />
                            <Column headerStyle={{ width: "8rem", textAlign: "center", justifyContent: "left" }} field="id" header="Número" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdExpedicionTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="semana" header="Semana" sortable></Column>
                            <Column headerStyle={{ width: "8rem" }} field="fecha" header="Fecha" sortable body={fechaTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="estado" header="Estado" sortable body={estadoExpedicionTemplate}></Column>
                            <Column headerStyle={{ width: "10rem" }} field="agencia.nombre" header="Agencia" sortable></Column>
                            <Column field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "6em" }} field="bultos.length" header="Bultos" sortable></Column>
                            <Column field="descripcion" header="Más info" sortable></Column>
                            <Column headerStyle={{ width: "2rem" }} field="id" header="Editar" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "left" }} body={botonDialogIdTemplate}></Column>
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de expediciones obtenidas: <strong>{expediciones?.length}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
