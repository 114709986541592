import React from "react";
import moment from "moment";
import { BotonIdTexto } from "./BotonIdTexto";
import { Card } from "primereact/card";
import { EstadoTexto } from "./EstadoTexto";

export function ItemTexto(props) {
    const { item } = props;

    let subTitle = (
        <>
            <i className="pi pi-hashtag"></i> {item?.ref}
        </>
    );

    return (
        <Card style={{ margin: "10px" }} title={item.serial} subTitle={subTitle}>
            <div className="p-fluid p-grid">
                <div className="p-field p-col-1">
                    <BotonIdTexto icono="building" ruta="/item/">
                        {item.id}
                    </BotonIdTexto>
                </div>
                <div style={{ marginTop: "7px" }} className="p-field p-col-1">
                    <EstadoTexto estado={item?.estadoItem} />
                </div>
                <div style={{ marginTop: "7px" }} className="p-field p-col-2">
                    {moment(item.fecha).format("DD/MM/YYYY HH:mm")}
                </div>
                {item?.comentario && (
                    <div style={{ marginTop: "2px" }} className="p-field p-col-12">
                        {item.comentario}
                    </div>
                )}
            </div>
        </Card>
    );
}
