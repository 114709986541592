import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { TrazaTexto } from "../componentes/TrazaTexto";
import { AvisoTexto } from "../componentes/AvisoTexto";
import { map } from "lodash";
import { AvisoNuevo } from "../componentes/AvisoNuevo";
import { AvisoActualizador } from "../componentes/AvisoActualizador";
import { TabView, TabPanel } from "primereact/tabview";
import { Fieldset } from "primereact/fieldset";
import { IncidenciaNueva } from "../componentes/IncidenciaNueva";
import { IncidenciaTexto } from "../componentes/IncidenciaTexto";
import { IncidenciaActualizador } from "../componentes/IncidenciaActualizador";
import { ItemActualizador } from "../componentes/ItemActualizador";

export const ItemDatos = (props) => {
    const { id } = useParams();
    const [datosItem, setDatosItem] = useState();
    const toast = useRef();
    const history = useHistory();
    const [actEstado, setActEstado] = useState();
    const [reload, setReload] = useState(Date.now());

    const print = () => {
        window.print();
    };

    function totalAvisoPendientes() {
        let totalAvisoActivos = 0;
        datosItem?.avisos.forEach((aviso) => {
            if (aviso.activo) {
                totalAvisoActivos++;
            }
        });
        return totalAvisoActivos;
    }

    function totalIncidenciasNoCerradas() {
        let totalIncidenciasNoCerradas = 0;
        datosItem?.incidencias.forEach((incidencia) => {
            if (!incidencia.cerrado) {
                totalIncidenciasNoCerradas++;
            }
        });
        return totalIncidenciasNoCerradas;
    }

    async function getDatosItem(idParam) {
        await getGeneralApi("getDatosItem", idParam)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    const datosItemEntrada = respuesta.data.items[0];
                    setDatosItem(datosItemEntrada);
                    setActEstado(datosItemEntrada?.estadoItem);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getDatosItem(id);
    }, [id, reload]);

    const headerTabAvisos = (
        <div style={{ marginRight: "4px" }}>
            Avisos: {totalAvisoPendientes()} pendientes de {datosItem?.avisos?.length}
        </div>
    );
    const headerTabIncidencias = (
        <div style={{ marginRight: "4px" }}>
            Incidencias: {totalIncidenciasNoCerradas()} abiertas de {datosItem?.incidencias?.length}
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="p-grid">
                                    <div className="p-md-6">
                                        <h2>
                                            <i className="p-pr-2 pi pi-building" style={{ fontSize: "2rem" }}></i>
                                            Puerta / Item nº <b>{id} </b> · {datosItem?.serial}
                                        </h2>
                                    </div>
                                    <div className="p-md-6">
                                        <div className="p-grid p-dir-rev">
                                            <div className="p-md-3 p-sm-6">
                                                <AvisoNuevo id={id} callBack={setReload} estadoItem={actEstado} modoItem={true} />{" "}
                                            </div>
                                            <div className="p-md-3 p-sm-6">
                                                <IncidenciaNueva id={id} callBack={setReload} estado={actEstado} modoItem={true} />
                                            </div>
                                            <div className="p-md-3 p-sm-6">{datosItem && <ItemActualizador item={datosItem} callBack={setReload} />}</div>
                                        </div>
                                    </div>
                                </div>
                                <TabView>
                                    <TabPanel header="Principal&nbsp;" rightIcon="pi pi-info-circle">
                                        <div className="invoice-to">
                                            <div className="bill-to p-mb-6">Datos Generales</div>
                                            <div className="invoice-to-info">
                                                <div>
                                                    Referencia: &nbsp;
                                                    <strong>{datosItem?.ref}</strong>
                                                </div>
                                                <div>
                                                    Estado:{" "}
                                                    <span>
                                                        <EstadoTexto estado={datosItem?.estadoItem} />
                                                    </span>
                                                </div>

                                                <div>
                                                    Fecha Estimada:&nbsp;
                                                    <strong>{datosItem?.fechaEstimada ? <Moment format="DD/MM/YYYY">{datosItem?.fechaEstimada}</Moment> : "No definida"}</strong>
                                                </div>
                                                <div>
                                                    Fecha Entrega:&nbsp;
                                                    <strong>{datosItem?.fechaEntrega ? <Moment format="DD/MM/YYYY">{datosItem?.fechaEntrega}</Moment> : "No definida"}</strong>
                                                </div>
                                                <div>
                                                    Comentarios: &nbsp;
                                                    <strong>{datosItem?.comentario}</strong>
                                                </div>
                                                <div>
                                                    <Fieldset legend={"Datos del Pedido nº " + datosItem?.pedido?.id} toggleable collapsed={false}>
                                                        <div className="p-grid">
                                                            <div className="p-md-3 p-sm-4">
                                                                <Button
                                                                    type="button"
                                                                    className="p-button-secondary"
                                                                    onClick={(e) => {
                                                                        history.push("/pedido/" + datosItem?.pedido?.id);
                                                                    }}
                                                                >
                                                                    <i className="p-pr-2 pi pi-id-card"></i> Pedido nº&nbsp;<b>{datosItem?.pedido?.id}</b>
                                                                </Button>
                                                            </div>
                                                            <div className="p-md-3 p-sm-4">
                                                                <Button
                                                                    type="button"
                                                                    className="p-button-secondary"
                                                                    onClick={(e) => {
                                                                        history.push("/presupuesto/" + datosItem?.pedido?.ppto?.id);
                                                                    }}
                                                                >
                                                                    <i className="p-pr-2 pi pi-ticket"></i> Presupuesto nº&nbsp;<b>{datosItem?.pedido?.ppto?.id}</b>
                                                                </Button>
                                                            </div>
                                                            <div className="p-md-3 p-sm-4">
                                                                <Button
                                                                    type="button"
                                                                    className="p-button-secondary"
                                                                    onClick={(e) => {
                                                                        history.push("/cliente/" + datosItem?.pedido?.ppto?.usuario?.id);
                                                                    }}
                                                                >
                                                                    <i className="p-pr-2 pi pi-user"></i>
                                                                    Cliente:&nbsp; <b>{datosItem?.pedido?.ppto?.usuario?.nombre}</b>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid">
                                                            <div className="p-md-3 p-sm-3">
                                                                Fecha del Pedido:
                                                                <strong>
                                                                    <Moment format="DD/MM/YYYY">{datosItem?.pedido?.fecha}</Moment>
                                                                </strong>
                                                            </div>
                                                            <div className="p-md-3 p-sm-3">
                                                                Fecha Teórica:
                                                                <strong>{datosItem?.fechaTeorica ? <Moment format="DD/MM/YYYY">{datosItem?.fechaTeorica}</Moment> : " No definida"}</strong>
                                                            </div>
                                                        </div>
                                                    </Fieldset>
                                                </div>
                                                {datosItem?.bulto && (
                                                    <div>
                                                        <Fieldset legend={"Datos del Bulto nº " + datosItem?.bulto?.id} toggleable collapsed={false}>
                                                            <div className="p-grid">
                                                                <div className="p-md-3 p-sm-3">
                                                                    <Button
                                                                        type="button"
                                                                        className="p-button-secondary"
                                                                        onClick={(e) => {
                                                                            history.push("/bulto/" + datosItem?.bulto?.id);
                                                                        }}
                                                                    >
                                                                        <i className="p-pr-2 pi pi-box"></i> Bulto nº&nbsp;<b>{datosItem?.bulto?.id}</b>
                                                                    </Button>
                                                                </div>
                                                                <div className="p-md-3 p-sm-3">
                                                                    <Button
                                                                        type="button"
                                                                        className="p-button-secondary"
                                                                        onClick={(e) => {
                                                                            history.push("/expedicion/" + datosItem?.bulto?.expedicion?.id);
                                                                        }}
                                                                    >
                                                                        <i className="p-pr-2 pi pi-send"></i> Expedicion nº&nbsp;<b>{datosItem?.bulto?.expedicion?.id}</b>
                                                                    </Button>
                                                                </div>
                                                                <div className="p-md-3 p-sm-3">
                                                                    Ref: <strong>{datosItem?.bulto?.ref}</strong>
                                                                </div>
                                                                <div className="p-md-3 p-sm-3">
                                                                    Descripción: <strong>{datosItem?.bulto?.descripcion}</strong>
                                                                </div>
                                                            </div>
                                                        </Fieldset>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel header={headerTabAvisos} rightIcon="pi pi-exclamation-triangle">
                                        {datosItem?.avisos && datosItem?.avisos?.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Avisos</div>
                                                {map(datosItem?.avisos, (aviso, index) => (
                                                    <div key={index}>
                                                        <div className="p-grid p-dir-rev" style={{ margin: "10px 0 -70px 0" }}>
                                                            <div className="p-col-2">
                                                                <AvisoActualizador callBack={setReload} aviso={aviso} modoItem={true} />
                                                            </div>
                                                        </div>
                                                        <AvisoTexto aviso={aviso}></AvisoTexto>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen avisos aún.</div>
                                        )}
                                    </TabPanel>

                                    <TabPanel header={headerTabIncidencias} rightIcon="pi pi-exclamation-circle">
                                        {datosItem?.incidencias && datosItem?.incidencias.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Incidencias</div>
                                                {map(datosItem?.incidencias, (incidencia, index) => (
                                                    <div key={index}>
                                                        <div className="p-grid p-dir-rev" style={{ margin: "10px 0 -70px 0" }}>
                                                            <div className="p-col-2">
                                                                <IncidenciaActualizador callBack={setReload} incidencia={incidencia} modoItem={true} />
                                                            </div>
                                                        </div>
                                                        <IncidenciaTexto incidencia={incidencia}></IncidenciaTexto>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen incidencias aún.</div>
                                        )}
                                    </TabPanel>

                                    <TabPanel header="Trazas&nbsp;" rightIcon="pi pi-ellipsis-h">
                                        {datosItem?.trazas && datosItem.trazas.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Traza</div>
                                                <ul>
                                                    {map(datosItem?.trazas, (traza, index) => (
                                                        <li key={index} style={{ margin: "10px" }}>
                                                            {index + 1}) <TrazaTexto traza={traza} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen trazas aún.</div>
                                        )}
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0 0 20px" }} className="p-field">
                            <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
