import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { Button } from "primereact/button";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { Calendar } from "primereact/calendar";
import Moment from "react-moment";
import moment from "moment";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { BotonIdTexto } from "../componentes/BotonIdTexto";

export const Bultos = () => {
    const [bultos, setBultos] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [fecha1, setfecha1] = useState(moment().subtract(1, "months").toDate());
    const [fecha2, setfecha2] = useState(moment().add(3, "months").toDate());
    const [reload, setReload] = useState(Date.now());

    addLocale("es", CALENDAR_LOCALE_ES);

    const toast = useRef(null);

    useEffect(() => {
        let fecha2HastaFinDia = moment(fecha2);
        fecha2HastaFinDia.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        const datosFormularioEnvio = {
            fecha1: fecha1,
            fecha2: fecha2HastaFinDia,
        };
        setLoading(true);
        postGeneralApi("listaBultos", datosFormularioEnvio)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setBultos(respuesta.data.items);
                    setLoading(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de expediciones" });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, [fecha1, fecha2, reload]);

    const onSubmit = (e) => {
        e.preventDefault();
        setReload(Date.now());
    };

    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar en expediciones" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const fechaTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <Moment format="DD/MM/YYYY">{data[props.field]}</Moment>
            </>
        );
    };

    const estadoExpedicionTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <EstadoTexto estado={data?.expedicion?.estadoExpedicion} />
            </>
        );
    };

    const botonIdExpedicionTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="pi pi-send" ruta="/expedicion/">
                    {String(data.expedicion.id).padStart(4, "0")}
                </BotonIdTexto>
            </>
        );
    };

    const botonIdBultoTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="pi pi-box" ruta="/bulto/">
                    {String(data.id).padStart(5, "0")}
                </BotonIdTexto>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>
                            <i className="p-pr-2 pi pi-box" style={{ fontSize: "1.5rem", float: "left" }}></i>Bultos
                        </h5>
                        <form onSubmit={onSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="desde">Expedición Desde</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="desde" showIcon value={fecha1} onChange={(e) => setfecha1(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="hasta">Expedición Hasta</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="hasta" showIcon value={fecha2} onChange={(e) => setfecha2(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="buscar" style={{ visibility: "hidden" }}>
                                        Buscar
                                    </label>
                                    <Button icon="pi pi-replay" label="Recargar" type="submit"></Button>
                                </div>
                            </div>
                        </form>
                        <DataTable value={bultos} paginator className="p-datatable-customers p-datatable-striped p-datatable-sm" rows={20} dataKey="id" rowHover globalFilter={globalFilter} emptyMessage="No se han encontrado bultos" loading={loading} header={tableHeader}>
                            <Column headerStyle={{ width: "8rem", textAlign: "center", justifyContent: "left" }} field="id" header="Número" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdBultoTemplate}></Column>
                            <Column headerStyle={{ width: "10rem" }} field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                            <Column headerStyle={{ width: "10rem" }} field="localizador" header="Localizador" sortable body={bodyTemplate}></Column>
                            <Column field="descripcion" header="Más info" sortable></Column>

                            <Column headerStyle={{ width: "6rem" }} field="expedicion.semana" header="Semana" sortable></Column>
                            <Column headerStyle={{ width: "8rem" }} field="expedicion.fecha" header="Fecha" sortable body={fechaTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="expedicion.estado" header="Estado" sortable body={estadoExpedicionTemplate}></Column>
                            <Column headerStyle={{ width: "10rem" }} field="expedicion.agencia.nombre" header="Agencia" sortable></Column>
                            <Column align={"right"} headerStyle={{ width: "2rem" }} field="numeroItems" header="Items" sortable></Column>
                            <Column headerStyle={{ width: "8rem", textAlign: "center", justifyContent: "left" }} field="id" header="Expedición" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdExpedicionTemplate}></Column>
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de expediciones obtenidas: <strong>{bultos?.length}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
