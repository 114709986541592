import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { useParams, useHistory } from "react-router";
import { map } from "lodash";
import { TabView, TabPanel } from "primereact/tabview";
import { ItemTexto } from "../componentes/ItemTexto";
import { BultoActualizador } from "../componentes/BultoActualizador";

export const BultoDatos = (props) => {
    const { id } = useParams();
    const [datosBulto, setDatosBulto] = useState();
    const toast = useRef();
    const history = useHistory();
    const [reload, setReload] = useState(Date.now());

    const print = () => {
        window.print();
    };

    async function getDatosBulto(idParam) {
        await getGeneralApi("getDatosBulto", idParam)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosBulto(respuesta.data.items[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getDatosBulto(id);
    }, [id, reload]);

    const headerTabPuertas = <div style={{ marginRight: "4px" }}>Puertas/Items: {datosBulto?.items?.length}</div>;

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="p-grid">
                                    <div className="p-md-6">
                                        <h2>
                                            <i className="p-pr-2 pi pi-box" style={{ fontSize: "2rem" }}></i>
                                            Bulto nº <b>{id} </b>
                                        </h2>
                                    </div>
                                    <div className="p-md-6">
                                        <div className="p-grid p-dir-rev">
                                            <div className="p-md-3 p-sm-6">{datosBulto && <BultoActualizador bulto={datosBulto} callBack={setReload} />}</div>
                                        </div>
                                    </div>
                                </div>
                                <TabView>
                                    <TabPanel header="Principal&nbsp;" rightIcon="pi pi-info-circle">
                                        <div className="invoice-to">
                                            <div className="bill-to p-mb-6">Datos Generales</div>
                                            <div className="invoice-to-info">
                                                <div>
                                                    Referencia: &nbsp;
                                                    <strong>{datosBulto?.ref}</strong>
                                                </div>
                                                <div>
                                                    Descripción: &nbsp;
                                                    <strong>{datosBulto?.descripcion}</strong>
                                                </div>
                                                <div>
                                                    Localizador:&nbsp;
                                                    <strong>{datosBulto?.localizador}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel header={headerTabPuertas} rightIcon="pi pi-building">
                                        {datosBulto?.items && datosBulto.items.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">Puertas/Items</div>
                                                {map(datosBulto?.items, (item, index) => (
                                                    <div key={index} style={{ margin: "10px" }}>
                                                        <ItemTexto item={item}></ItemTexto>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen Puertas/Items asociadas.</div>
                                        )}
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0 0 20px" }} className="p-field">
                            <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
