import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { Dropdown } from "primereact/dropdown";
import { estadosItem } from "../api/varios";

export function ItemActualizador(props) {
    const { callBack, item } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [estadoItemDrop, setEstadoItemDrop] = useState(item?.estadoItem);
    const labelGeneral = "Actualizar Item";

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            estadoItem: estadoItemDrop, //Hack para primeReact Dropdown
            ref: e.target.ref.value,
            comentario: e.target.comentario.value,
        };

        postGeneralApi("updateDatosItem", datosFormularioEnvio, item.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-field">
                            <label htmlFor="estadoItem">Estado</label>
                            {estadoItemDrop && <Dropdown name="estadoItem" value={estadoItemDrop} options={estadosItem} onChange={(e) => setEstadoItemDrop(e.value)} placeholder="Seleccionar el estado" />}
                        </div>
                        <div className="p-field">
                            <label htmlFor="ref">Referencia</label>
                            <br />
                            <InputText id="ref" name="ref" placeholder="Referencia" defaultValue={item?.ref} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="comentario">Detalles</label>
                            <br />
                            <InputTextarea id="comentario" name="comentario" placeholder="Comentarios" cols="40" rows="4" defaultValue={item?.comentario} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="actualizar" style={{ visibility: "hidden" }}>
                                Actualizar
                            </label>
                            <Button label="ACTUALIZAR" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
