import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router";
import { EstadoPptoTexto } from "../componentes/EstadoPptoTexto";
import { isAdminApi } from "../api/autenticacion";

export const PptoDatos = (props) => {
    const [datosPpto, setDatosPpto] = useState({});
    const [datosLinea, setDatosLinea] = useState({});
    const toast = useRef();
    const { id } = useParams();
    const history = useHistory();

    const print = () => {
        window.print();
    };

    useEffect(() => {
        getGeneralApi("getDatosPpto", id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosPpto(respuesta.data.items[0]);
                    setDatosLinea(respuesta.data.items[0].lineas[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, [id]);

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <h2>
                                    <i className="p-pr-2 pi pi-ticket" style={{ fontSize: "2rem" }}></i>
                                    Presupuesto <b>nº {id}</b>
                                </h2>
                                <div className="invoice-to">
                                    <div className="bill-to"></div>
                                    <div className="invoice-to-info">
                                        <div>
                                            <EstadoPptoTexto estado={datosPpto?.estado} />
                                        </div>
                                        <div>
                                            Fecha:&nbsp;
                                            <strong>
                                                <Moment format="DD/MM/YYYY">{datosPpto?.fecha}</Moment>
                                            </strong>
                                        </div>
                                        {datosPpto?.estado === 2 && (
                                            <div>
                                                A pedido:&nbsp;
                                                <strong>
                                                    <Moment format="DD/MM/YYYY">{datosPpto?.fechaPedido}</Moment>
                                                </strong>
                                            </div>
                                        )}
                                        <div>
                                            Ref: <b>{datosPpto?.ref}</b>{" "}
                                        </div>
                                        <div>
                                            Más datos:{" "}
                                            <b>
                                                {datosPpto?.nombre} {datosPpto?.apellidos}
                                                {(datosPpto?.direccion || datosPpto?.cp || datosPpto?.localidad) && (
                                                    <>
                                                        {" "}
                                                        ( {datosPpto?.direccion} · {datosPpto?.cp} · {datosPpto?.localidad})
                                                    </>
                                                )}
                                            </b>
                                        </div>

                                        <div>
                                            <Button
                                                type="button"
                                                className="p-button-secondary"
                                                onClick={(e) => {
                                                    history.push("/cliente/" + datosPpto?.usuario?.id);
                                                }}
                                            >
                                                <i style={{ paddingRight: "10px" }} className="pi pi-user"></i> Cliente: {datosPpto?.usuario?.nombre}
                                            </Button>
                                        </div>
                                        {isAdminApi() && (
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="p-button-primary"
                                                    onClick={(e) => {
                                                        getGeneralApi("procAltaPed", id)
                                                            .then((respuesta) => {
                                                                if (respuesta?.data?.success) {
                                                                    toast.current.show({ severity: "success", summary: "OK", detail: "Alta correcta: " + respuesta?.data?.mensaje });
                                                                } else {
                                                                    toast.current.show({ severity: "error", summary: "Error", detail: "Error: " + respuesta?.data?.errores?.error });
                                                                    //toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos!!!" });
                                                                }
                                                            })
                                                            .catch((response) => {
                                                                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
                                                            });
                                                    }}
                                                >
                                                    Generar Pedido a pertir de este presupuesto
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="invoice-to">
                                    <div className="bill-to"></div>
                                    <div className="invoice-to-info">
                                        <div>
                                            Cantidad: <b>{datosLinea?.cantidad}</b>
                                        </div>
                                        <div>
                                            Información:
                                            <pre>{datosLinea?.txt}</pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0 0 20px" }} className="p-field">
                            <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
