import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import Moment from "react-moment";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { EstadoPptoTexto } from "../componentes/EstadoPptoTexto";
import { BotonIdTexto } from "../componentes/BotonIdTexto";
import { Dropdown } from "primereact/dropdown";
import { estadosPresupuestoMasTodos } from "../api/varios";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";

export const Pptos = () => {
    const [pptos, setPptos] = useState(null);
    const [selectedPptos, setSelectedPptos] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [estadoPptoDrop, setEstadoPptoDrop] = useState("2");
    const [fecha1, setfecha1] = useState(moment().subtract(12, "months").toDate());
    const [fecha2, setfecha2] = useState(moment().toDate());
    const [reload, setReload] = useState(Date.now());

    addLocale("es", CALENDAR_LOCALE_ES);

    const toast = useRef(null);
    const history = useHistory();

    useEffect(() => {
        let fecha2HastaFinDia = moment(fecha2);
        fecha2HastaFinDia.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        const datosFormularioEnvio = {
            estado: estadoPptoDrop, //Hack para primeReact Dropdown
            fecha1: fecha1,
            fecha2: fecha2HastaFinDia,
        };
        setLoading(true);
        postGeneralApi("listaPptos", datosFormularioEnvio)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setPptos(respuesta.data.items);
                    setLoading(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de presupuestos" });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, [estadoPptoDrop, fecha1, fecha2, reload]);

    const onSubmit = (e) => {
        e.preventDefault();
        setReload(Date.now());
    };

    const customer2TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar en presupuestos" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const fechaTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <Moment format="DD/MM/YYYY">{data[props.field]}</Moment>
            </>
        );
    };

    const fechaPedidoTemplate = (data, props) => {
        return (
            data?.estado === 2 && (
                <>
                    <span className="p-column-title">{props.header}</span>
                    <Moment format="DD/MM/YYYY">{data[props.field]}</Moment>
                </>
            )
        );
    };

    const estadoPedidoTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <EstadoPptoTexto estado={data?.estado} />
            </>
        );
    };

    const botonIdTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                <BotonIdTexto icono="ticket" ruta="/presupuesto/">
                    {String(data.id).padStart(5, "0")}
                </BotonIdTexto>
            </>
        );
    };

    const usuarioTemplate = (data) => (
        <Button
            type="button"
            className="p-button-secondary"
            onClick={(e) => {
                history.push("/cliente/" + data.usuario.id);
            }}
        >
            <i className="pi pi-user"> </i>&nbsp; {data.usuario.nombre} {data.usuario.apellidos}
        </Button>
    );

    const masDatosTemplate = (data, props) => {
        let datosDireccion = "";
        if (data.direccion || data.cp || data.localidad) {
            datosDireccion += " (" + data.direccion + " · " + data.cp + " · " + data.localidad + ") ";
        }
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data?.nombre} {data?.apellidos} {datosDireccion}
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>
                            <i className="p-pr-2 pi pi-ticket" style={{ fontSize: "1.5rem", float: "left" }}></i>Presupuestos
                        </h5>
                        <form onSubmit={onSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="estado">Estado </label>
                                    {estadoPptoDrop && <Dropdown name="estado" value={estadoPptoDrop} options={Array.from(estadosPresupuestoMasTodos)} onChange={(e) => setEstadoPptoDrop(e.value)} placeholder="Seleccionar Estado" />}
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="desde">Desde</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="desde" showIcon value={fecha1} onChange={(e) => setfecha1(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-2">
                                    <label htmlFor="hasta">Hasta</label>
                                    <Calendar locale="es" dateFormat="dd/mm/yy" id="hasta" showIcon value={fecha2} onChange={(e) => setfecha2(e.value)}></Calendar>
                                </div>
                                <div className="p-field p-col-1">
                                    <label htmlFor="buscar" style={{ visibility: "hidden" }}>
                                        Buscar
                                    </label>
                                    <Button icon="pi pi-replay" label="Recargar" type="submit"></Button>
                                </div>
                            </div>
                        </form>
                        <DataTable
                            value={pptos}
                            paginator
                            className="p-datatable-customers p-datatable-striped p-datatable-sm"
                            rows={20}
                            dataKey="id"
                            rowHover
                            selection={selectedPptos}
                            onSelectionChange={(e) => setSelectedPptos(e.value)}
                            globalFilter={globalFilter}
                            emptyMessage="No se han encontrado presupuestos"
                            loading={loading}
                            header={customer2TableHeader}
                        >
                            <Column headerStyle={{ width: "8rem", textAlign: "center" }} field="id" header="Número" sortable bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={botonIdTemplate}></Column>
                            <Column field="ref" header="Ref" sortable body={bodyTemplate}></Column>
                            <Column field="nombre" header="Más info" sortable body={masDatosTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="fecha" header="Fecha" sortable body={fechaTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="fechaPedido" header="Fecha Pedido" sortable body={fechaPedidoTemplate}></Column>
                            <Column headerStyle={{ width: "8rem" }} field="estado" header="Estado" sortable body={estadoPedidoTemplate}></Column>
                            {/*
                            <Column headerStyle={{ width: "8rem" }} field="pedido" header="Pedido" sortable body={pedidoTemplate}></Column>
                            */}
                            <Column headerStyle={{ width: "18rem" }} field="usuario.id" header="Cliente" sortable body={usuarioTemplate}></Column>
                        </DataTable>
                        <div style={{ textAlign: "right" }}>
                            Total de presupuestos obtenidos: <strong>{pptos?.length}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
