import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router";
import { EstadoTexto } from "../componentes/EstadoTexto";
import { map } from "lodash";
import { TabView, TabPanel } from "primereact/tabview";
import { BultoTexto } from "../componentes/BultoTexto";
import { ExpedicionDialog } from "../componentes/ExpedicionDialog";

export const ExpedicionDatos = (props) => {
    const { id } = useParams();
    const [datosExpedicion, setDatosExpedicion] = useState();
    const toast = useRef();
    const history = useHistory();
    const [reload, setReload] = useState(Date.now());

    const print = () => {
        window.print();
    };

    async function getDatosExpedicion(idParam) {
        await getGeneralApi("getDatosExpedicion", idParam)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosExpedicion(respuesta.data.items[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getDatosExpedicion(id);
    }, [id, reload]);

    const headerTabBultos = <div style={{ marginRight: "4px" }}>Bultos: {datosExpedicion?.bultos?.length} asignados</div>;

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="p-grid">
                                    <div className="p-md-6">
                                        <h2>
                                            <i className="p-pr-2 pi pi-send" style={{ fontSize: "2rem" }}></i>
                                            Expedición nº <b>{id} </b>
                                        </h2>
                                    </div>
                                    <div className="p-md-6">
                                        <div className="p-grid p-dir-rev">
                                            <div className="p-md-3 p-sm-6">{datosExpedicion && <ExpedicionDialog modoCrear={false} expedicion={datosExpedicion} callBack={setReload} labelEditar="Editar" />}</div>
                                        </div>
                                    </div>
                                </div>
                                <TabView>
                                    <TabPanel header="Principal&nbsp;" rightIcon="pi pi-info-circle">
                                        <div className="invoice-to">
                                            <div className="bill-to p-mb-6">Datos Generales</div>
                                            <div className="invoice-to-info">
                                                <div>
                                                    Referencia: &nbsp;
                                                    <strong>{datosExpedicion?.ref}</strong>
                                                </div>
                                                <div>
                                                    Estado:{" "}
                                                    <span>
                                                        <EstadoTexto estado={datosExpedicion?.estadoExpedicion} /> {datosExpedicion?.estadoExpedicion}
                                                    </span>
                                                </div>

                                                <div>
                                                    Fecha:&nbsp;
                                                    <strong>{datosExpedicion?.fecha ? <Moment format="DD/MM/YYYY">{datosExpedicion?.fecha}</Moment> : "No definida"}</strong>
                                                </div>
                                                <div>
                                                    Semana:&nbsp;
                                                    <strong>{datosExpedicion?.semana}</strong>
                                                </div>
                                                <div>
                                                    Descripción: &nbsp;
                                                    <strong>{datosExpedicion?.descripcion}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel header={headerTabBultos} rightIcon="pi pi-box">
                                        {datosExpedicion?.bultos && datosExpedicion?.bultos?.length > 0 ? (
                                            <div className="invoice-to">
                                                <div className="bill-to">
                                                    <i className="p-pr-2 pi pi-box" style={{ fontSize: "2rem" }}></i>
                                                    Bultos
                                                </div>
                                                {map(datosExpedicion?.bultos, (bulto, index) => (
                                                    <div key={index}>
                                                        <div className="p-grid p-dir-rev" style={{ margin: "40px 40px -80px 0" }}>
                                                            <Button
                                                                type="button"
                                                                className="p-button-secondary"
                                                                onClick={(e) => {
                                                                    history.push("/bulto/" + bulto?.id);
                                                                }}
                                                            >
                                                                <i className="p-pr-2 pi pi-box"></i> Bulto nº&nbsp;<b>{bulto?.id}</b>
                                                            </Button>
                                                        </div>
                                                        <BultoTexto bulto={bulto}></BultoTexto>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-mt-6">No existen bultos asignados.</div>
                                        )}
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div style={{ padding: "20px 0 0 20px" }} className="p-field">
                            <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
