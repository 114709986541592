import React, { useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";

export function BultoActualizador(props) {
    const { callBack, bulto } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const labelGeneral = "Actualizar Bulto";

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            ref: e.target.ref.value,
            descripcion: e.target.descripcion.value,
            localizador: e.target.localizador.value,
        };

        postGeneralApi("updateDatosBulto", datosFormularioEnvio, bulto.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-field">
                            <label htmlFor="ref">Referencia</label>
                            <br />
                            <InputText id="ref" name="ref" placeholder="Referencia" defaultValue={bulto?.ref} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="descripcion">Detalles</label>
                            <br />
                            <InputTextarea id="descripcion" name="descripcion" placeholder="Descripción" cols="40" rows="4" defaultValue={bulto?.descripcion} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="localizador">Localizador</label>
                            <br />
                            <InputText id="localizador" name="localizador" placeholder="Localizador del bulto" defaultValue={bulto?.localizador} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="actualizar" style={{ visibility: "hidden" }}>
                                Actualizar
                            </label>
                            <Button label="ACTUALIZAR" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
