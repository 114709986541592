import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

export const Agencias = () => {
    const [agencias, setAgencias] = useState({});
    const [loading, setLoading] = useState(true);
    const toast = useRef();

    useEffect(() => {
        getGeneralApi("listaAgencias")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setAgencias(respuesta.data.items);
                    setLoading(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de tipologías." });
                }
            })
            .catch((response) => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const activoBody = (data, props) => {
        return <Checkbox checked={data[props.field]} disabled></Checkbox>;
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="p-grid table-demo">
                <div className="p-col-12">
                    <div className="card">
                        <h5>
                            <i className="p-pr-2 pi pi-compass" style={{ fontSize: "1.5rem", float: "left" }}></i>Agencias de Transporte
                        </h5>
                        <p>Agencias de Transporte para las expediciones</p>
                        <DataTable value={agencias} className="p-datatable-customers p-datatable-striped p-datatable-sm" dataKey="id" rowHover emptyMessage="No se han encontrado agencias" loading={loading}>
                            <Column headerStyle={{ width: "6em" }} body={(data, props) => props.rowIndex + 1}></Column>
                            <Column field="id" headerStyle={{ width: "6em" }} header="Id" sortable body={bodyTemplate}></Column>
                            <Column field="activa" headerStyle={{ width: "4rem" }} header="Activa" sortable body={activoBody}></Column>
                            <Column field="nombre" headerStyle={{ width: "10em" }} header="Nombre" sortable body={bodyTemplate}></Column>
                            <Column field="descripcion" header="Descripción" sortable body={bodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};
