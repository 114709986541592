import { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { isEmailValido } from "../utiles/validaciones";
import { getGeneralApi } from "../api/general";
import { postGeneralApi } from "../api/general";
import { useParams } from "react-router";
import { RolTexto } from "../componentes/RolTexto";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const Gestor = () => {
    const [datosFormulario, setDatosFormulario] = useState({});
    const [datosDptos, setDatosDptos] = useState({});
    const [activoChecked, setActivoChecked] = useState(false);
    const [notificacionesChecked, setNotificacionesChecked] = useState(false);
    const toast = useRef();
    const { id } = useParams();
    const [dptoDrop, setDptoDrop] = useState({});
    const history = useHistory();

    async function getDatosGestor(idParam) {
        await getGeneralApi("listaDptos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosDptos(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de departamentos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });

        await getGeneralApi("getDatosGestor", idParam)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    const datosGestor = respuesta.data.items[0];
                    setDatosFormulario(datosGestor);
                    setActivoChecked(datosGestor.activo); //Hack para primeReact Checkbox
                    setNotificacionesChecked(datosGestor.notificaciones); //Hack para primeReact Checkbox
                    setDptoDrop(datosGestor.dpto.id); //Hack para Dropdown
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getDatosGestor(id);
    }, [id]);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            activo: activoChecked, //Hack para primeReact Checkbox
            email: e.target.email.value,
            password: e.target.password.value,
            nombre: e.target.nombre.value,
            apellidos: e.target.apellidos.value,
            telefono: e.target.telefono.value,
            comentario: e.target.comentario.value,
            dpto: { id: dptoDrop }, //Hack para primeReact Dropdown
            notificaciones: notificacionesChecked, //Hack para primeReact Checkbox
            emailNotificaciones: e.target.emailNotificaciones.value,
            cargo: e.target.cargo.value,
            comentarioGestor: e.target.comentarioGestor.value,
        };

        if (!isEmailValido(datosFormularioEnvio.email)) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "No es una dirección de email válida" });
            return;
        }

        postGeneralApi("updateDatosGestor", datosFormularioEnvio, id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <div className="p-grid">
                            <div className="p-col">
                                <h5>
                                    <i className="p-pr-2 pi pi-user-plus" style={{ fontSize: "2rem" }}></i>
                                    Datos de Gestor nº {id}
                                </h5>
                            </div>
                            <div className="p-col" style={{ textAlign: "right" }}>
                                <RolTexto>{datosFormulario.rol}</RolTexto>
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div style={{ margin: "20px 0" }} className="p-field-checkbox p-col-12">
                                    <Checkbox inputId="activo" name="activo" defaultValue={datosFormulario.activo} checked={activoChecked} onChange={(evento) => setActivoChecked(evento.checked)} />
                                    <label htmlFor="activo">{activoChecked ? "Desactivar gestor" : "Activar gestor"}</label>
                                </div>

                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="email">Email (Username)</label>
                                    <InputText name="email" type="text" defaultValue={datosFormulario.email} />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="password">Password</label>
                                    <InputText name="password" type="text" defaultValue={datosFormulario.password} />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="fecha">Fecha de alta</label>
                                    <InputText name="fecha" type="text" defaultValue={moment(datosFormulario.fecha).format("DD/MM/YYYY")} disabled />
                                </div>

                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="nombre">Nombre</label>
                                    <InputText name="nombre" type="text" defaultValue={datosFormulario.nombre} />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="apellidos">Apellidos</label>
                                    <InputText name="apellidos" type="text" defaultValue={datosFormulario.apellidos} />
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <InputText name="telefono" type="text" defaultValue={datosFormulario.telefono} />
                                </div>

                                <div className="p-field p-col-12">
                                    <label htmlFor="comentario">Información admin</label>
                                    <InputTextarea name="comentario" rows="4" defaultValue={datosFormulario.comentario} />
                                </div>

                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="dpto">Departamento</label>
                                    <Dropdown name="dpto" optionValue="id" optionLabel="nombre" value={dptoDrop} options={Array.from(datosDptos)} onChange={(e) => setDptoDrop(e.value)} placeholder="Seleccionar Departamento" />
                                </div>

                                <div style={{ margin: "20px 0" }} className="p-field-checkbox p-col-12">
                                    <Checkbox inputId="notificaciones" name="notificaciones" defaultValue={datosFormulario.notificaciones} checked={notificacionesChecked} onChange={(evento) => setNotificacionesChecked(evento.checked)} />
                                    <label htmlFor="notificaciones">{notificacionesChecked ? "Desactivar notificaciones" : "Activar notificaciones"}</label>
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="emailNotificaciones">Email Notificaciones</label>
                                    <InputText name="emailNotificaciones" type="text" defaultValue={datosFormulario.emailNotificaciones} />
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="cargo">Cargo</label>
                                    <InputText name="cargo" type="text" defaultValue={datosFormulario.cargo} />
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="comentarioGestor">Informacion del gestor</label>
                                    <InputTextarea name="comentarioGestor" rows="4" defaultValue={datosFormulario.comentarioGestor} />
                                </div>
                                <div style={{ padding: "16px 0 0 16px" }} className="p-field">
                                    <Button label="ACTUALIZAR" type="submit"></Button>
                                </div>

                                <div style={{ padding: "16px 0 0 16px" }} className="p-field">
                                    <Button label="Volver" icon="pi pi-angle-double-left" type="button" className="p-button-secondary" onClick={(e) => history.goBack()} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
