import React from "react";
import { Card } from "primereact/card";

export function BultoTexto(props) {
    const { bulto } = props;

    let subTitle = (
        <>
            <i className="pi pi-tag"></i> {bulto?.ref}
        </>
    );

    return (
        <Card style={{ margin: "20px" }} title={"nº " + bulto?.id} subTitle={subTitle}>
            <div style={{ marginTop: "15px" }}>{bulto?.descripcion}</div>
        </Card>
    );
}
