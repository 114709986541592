import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { incidenciaEstado } from "../api/varios";

export function IncidenciaActualizador(props) {
    const { callBack, incidencia, modoItem = false } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [estadoDrop, setEstadoDrop] = useState(incidencia.estado);
    const [cerradoChecked, setCerradoChecked] = useState(incidencia.cerrado);
    const [cerradoCalidadChecked, setCerradoCalidadChecked] = useState(incidencia.cerradoCalidad);
    const labelGeneral = "Actualizar Incidencia";

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            estado: estadoDrop, //Hack para primeReact Dropdown
            cerrado: cerradoChecked, //Hack para primeReact Checkbox
            cerradoCalidad: cerradoCalidadChecked, //Hack para primeReact Checkbox
            origen: e.target.origen.value,
            motivo: e.target.motivo.value,
            detalles: e.target.detalles.value,
            solucion: e.target.solucion.value,
        };
        postGeneralApi(modoItem ? "updateDatosIncidenciaItem" : "updateDatosIncidencia", datosFormularioEnvio, incidencia.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: respuesta?.data?.mensaje });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined p-button-warning" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-field">
                            <label htmlFor="estado">Estado</label>
                            <Dropdown name="estado" value={estadoDrop} options={Array.from(incidenciaEstado)} onChange={(e) => setEstadoDrop(e.value)} placeholder="Seleccionar el estado" />
                        </div>
                        <div className="p-field">
                            <label htmlFor="origen">Origen</label>
                            <br />
                            <InputText id="origen" name="origen" placeholder="Origen de la incidencia" defaultValue={incidencia.origen} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="motivo">Motivo</label>
                            <br />
                            <InputText id="motivo" name="motivo" placeholder="Motivo de la incidencia" defaultValue={incidencia.motivo} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="detalles">Detalles</label>
                            <br />
                            <InputTextarea id="detalles" name="detalles" placeholder="Detalles de la incidencia" cols="40" rows="4" defaultValue={incidencia.detalles} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="solucion">Solución</label>
                            <br />
                            <InputTextarea id="solucion" name="solucion" placeholder="Solución de la incidencia" cols="40" rows="4" defaultValue={incidencia.solucion} />
                        </div>
                        <div className="p-field-checkbox">
                            <Checkbox inputId="cerrado" name="cerrado" checked={cerradoChecked} onChange={(evento) => setCerradoChecked(evento.checked)} />
                            <label htmlFor="cerrado">Cerrado</label>
                        </div>
                        <div className="p-field-checkbox">
                            <Checkbox inputId="cerradoCalidad" name="cerradoCalidad" checked={cerradoCalidadChecked} onChange={(evento) => setCerradoCalidadChecked(evento.checked)} />
                            <label htmlFor="cerradoCalidad">Cerrado por Calidad</label>
                        </div>
                        <div className="p-field">
                            <label htmlFor="actualizar" style={{ visibility: "hidden" }}>
                                Actualizar
                            </label>
                            <Button className="p-button-warning" label="ACTUALIZAR" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
